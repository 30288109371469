<template>
  <div class="jmsetting">
    <NavBar name="特殊事项减免设置" />
    <div class="formline">
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="time"
            class="offset"
            start-placeholder="减免时间"
            value-format="yyyy-MM-dd"
            type="daterange"
            @change="timeSet"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <!-- <MyInput v-model="searchForm.worker_name" placeholder="输入提交人">
            <template slot="pre">提交人:</template>
          </MyInput> -->
          <el-select
            clearable
            @change="getList(1)"
            v-model="searchForm.worker_id"
            placeholder="请选择提交人"
          >
            <el-option
              v-for="item in workerList"
              :value="item.user_id"
              :key="item.user_id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchForm.is_agree"
            @change="getList(1)"
            placeholder="审批状态"
          >
            <el-option label="待审核" :value="10"></el-option>
            <el-option label="同意" :value="20"></el-option>
            <el-option label="拒绝" :value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="getList">
            搜索
            <template slot="preImage">
              <img src="../../../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="getMerchantsList">
            新增基础费用减免
            <template slot="preImage">
              <img src="../../../../unit/img/zj.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
    </div>
    <div class="pub-table">
      <el-table
        :data="tableData"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
        v-loading="loading"
      >
        <el-table-column label="提交人" prop="worker_name"></el-table-column>
        <el-table-column label="提交时间" prop="create_at"></el-table-column>
        <el-table-column label="减免商户" prop="name"></el-table-column>
        <el-table-column
          label="减免金额（元）"
          prop="reduce_price"
        ></el-table-column>
        <el-table-column
          label="总减免金额（元）"
          prop="total_price"
        ></el-table-column>
        <el-table-column label="原因" prop="coupon_reason"></el-table-column>
        <el-table-column label="凭证">
          <template #default="{ row }">
            <pop tips="查看" @myclick="checkvoucher(row)">
              <img src="../../../../unit/img/yl.png" class="icon" alt="" />
            </pop>
          </template>
        </el-table-column>
        <el-table-column label="审批状态">
          <template #default="{ row }">
            <span v-if="row.is_agree == 10">待审核</span>
            <span v-if="row.is_agree == 20">同意</span>
            <span v-if="row.is_agree == 30">拒绝</span>
          </template>
        </el-table-column>
        <el-table-column
          label="审批人"
          prop="agree_worker_name"
        ></el-table-column>
        <el-table-column label="审批时间" prop="agree_at">
          <template #default="{ row }">
            <span v-if="row.is_agree == 10">/</span>
            <span v-else>{{ row.agree_at }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="agree_remark"></el-table-column>
        <el-table-column label="操作">
          <template #default="{ row }">
            <pop tips="审批" @myclick="handlesh(row)" v-if="row.is_agree == 10">
              <img src="../../../../unit/img/sh.png" class="icon" alt="" />
            </pop>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="apiPage" style="text-align: right">
      <el-pagination
        next-text="下一页"
        prev-text="上一页"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="getList"
      ></el-pagination>
    </div>
    <!--基础费用减免  -->
    <el-dialog
      title="基础费用减免设置"
      :visible.sync="rentderateVisible"
      @close="diaclose"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" ref="jmform" label-width="150px">
        <el-form-item label="减免商户">
          <el-select
            v-model="form.merchants_id"
            @change="changeMerchants"
            placeholder="请选择减免商户"
          >
            <el-option :value="-1" label="全部"></el-option>
            <el-option
              v-for="item in merchantsList"
              :value="item.merchants_id"
              :key="item.merchants_id"
              :label="item.contact_name"
            ></el-option>
          </el-select>
          <span v-if="form.merchants_id == -1"
            >{{ merchantsList.length }}(人)</span
          >
        </el-form-item>
        <el-form-item label="商户身份证号">
          <el-input
            v-model="form.merchants_card_id"
            class="label-width"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="减免项目">
          <el-select v-model="form.apply_range" placeholder="请选择减免项目">
            <el-option
              v-for="item in paymentTypeList"
              :value="item.id"
              :key="item.id"
              :label="item.contract_fee_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="减免金额">
          <el-input
            v-model="form.reduce_price"
            type="number"
            @blur="blurPrice"
            class="label-width"
            placeholder="请输入减免金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="总减免金额">
          <el-input
            v-model="form.totalPrice"
            class="label-width"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="原因">
          <el-input v-model="form.coupon_reason" class="label-width"></el-input>
        </el-form-item>
        <el-form-item label="上传凭证">
          <div
            class="upload-imgx-box"
            v-for="(item, index) in form.coupon_voucher"
            :key="index"
          >
            <img class="upload-img" :src="item" alt="" />
            <span @click="deleteImg(index)">x</span>
          </div>
          <el-upload
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="filechange"
          >
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <MyButton @click="rentderateVisible = false">取消</MyButton>
        <MyButton
          left
          @click="add"
          type="primary"
          :disabled="!form.merchants_id || !form.reduce_price"
          >新增</MyButton
        >
      </template>
    </el-dialog>

    <!-- Pingz -->
    <el-dialog
      title="凭证"
      :visible.sync="vouchervisible"
      :close-on-click-modal="false"
    >
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item, index) in voucher" :key="index">
          <img class="voucher-img" v-img :src="$common.hashost(item)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <!-- sp -->
    <el-dialog
      title="审批"
      :visible.sync="spvisible"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="备注">
          <el-input
            v-model="spform.agree_remark"
            type="textarea"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="spconfirm(30)">拒绝</el-button>
        <el-button @click="spconfirm(20)" type="primary">同意</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      form: {
        merchants_ids: [],
        coupon_voucher: [],
        coupon_type: 10,
        apply_range: "",
      },
      searchForm: {
        coupon_type: 10,
      },
      total: 0,
      time: "",
      rentderateVisible: false,
      merchantsList: [],
      tableData: [
        {
          id: 1,
        },
      ],
      vouchervisible: false,
      voucher: [],
      spform: {
        is_agree: "",
        agree_remark: "",
        coupon_id: "",
      },
      spvisible: false,
      workerList: [],
      paymentTypeList: [],
    };
  },
  created() {
    this.getList();
    this.getWorkerList();
    this.getPaymentType();
  },
  methods: {
    getPaymentType() {
      this.$request.HttpGet("/contract_fee_setting/listAll").then((res) => {
        this.paymentTypeList =
          res.data?.filter((item) => item.repeated_type != 20) ?? [];
      });
    },
    getList(page = 1) {
      this.loading = true;
      this.tableData = [];
      this.searchForm.pageNum = page;
      // "/coupon/couponList"
      this.$request
        .HttpGet("/coupon/list", this.searchForm)
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.list) {
            // res.data.list.forEach((item) => {
            //   item.worker_name = row.worker_name;
            // });
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    timeSet(val) {
      this.searchForm.beginTime = val ? val[0] : "";
      this.searchForm.endTime = val ? val[1] : "";
    },
    getMerchantsList() {
      this.$request
        .HttpPost("/merchants/list", {
          pageNum: 1,
          pageSize: 10000,
          contract_status: 1,
          coupon_status: 1,
        })
        .then((res) => {
          this.rentderateVisible = true;
          this.merchantsList = res.data.list || [];
        });
    },
    changeMerchants(id) {
      this.form.merchants_ids = [];
      if (id == -1) {
        for (let i in this.merchantsList) {
          this.form.merchants_ids.push(this.merchantsList[i].merchants_id);
        }
        this.form.merchants_stall_name = "全部";
        this.form.name = "全部";
      } else {
        for (let i in this.merchantsList) {
          if (this.merchantsList[i].merchants_id == id) {
            this.form.merchants_ids = [this.merchantsList[i].merchants_id];
            this.form.merchants_card_id =
              this.merchantsList[i].merchants_card_id_original;
            this.form.name = this.merchantsList[i].contact_name;
          }
        }
      }
      if (this.form.reduce_price) {
        this.calculate();
      }
    },
    blurPrice() {
      if (this.form.merchants_ids.length == 0) {
        this.$message({
          message: "请选择减免商户",
          type: "error",
        });
        return;
      }
      this.calculate();
    },
    calculate() {
      let val = parseFloat(this.form.reduce_price) || 0;
      if (this.form.merchants_id == -1) {
        this.form.totalPrice = (this.merchantsList.length * val).toFixed(2) * 1;
      } else {
        this.form.totalPrice = val;
      }
      this.$forceUpdate();
    },
    filechange(file, filelist) {
      console.log(filelist);
      let obj = new FormData();
      obj.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", obj).then((res) => {
        this.form.coupon_voucher.push(
          process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl
        );
      });
    },
    add() {
      this.$myconfirm("此操作将产生费用，是否继续").then(() => {
        this.$refs.jmform.resetFields();
        this.rentderateVisible = false;
        // this.form.coupon_voucher
        this.$request.HttpPost("/coupon/add", this.form).then((res) => {
          this.$common.notifySuccess(res.msg);
          this.form = {
            merchants_ids: [],
            merchants_card_id: "",
            apply_range: "",
            reduce_price: "",
            totalPrice: 0,
            name: "",
            coupon_type: 10,
            coupon_voucher: [],
          };

          this.getList();
        });
      });
    },
    deleteImg(index) {
      this.form.coupon_voucher.splice(index, 1);
    },
    checkvoucher(row) {
      console.log(row);
      if (!row.coupon_voucher)
        return this.$message({
          message: "暂无凭证",
          type: "warning",
        });
      try {
        this.voucher = JSON.parse(row.coupon_voucher);
      } catch {
        this.voucher = [row.coupon_voucher];
      }
      if (this.voucher.length == 0) {
        return this.$message({
          message: "暂无凭证",
          type: "warning",
        });
      }
      this.vouchervisible = true;
    },
    diaclose() {
      this.form = {
        merchants_ids: [],
        merchants_card_id: "",
        apply_range: "",
        reduce_price: "",
        totalPrice: 0,
        name: "",
        coupon_type: 10,
        coupon_voucher: [],
      };
    },
    handlesh(item) {
      this.spform.coupon_id = item.coupon_id;
      this.spvisible = true;
    },
    spconfirm(type) {
      this.spform.is_agree = type;
      this.$request.HttpPost("/coupon/isAgree", this.spform).then((res) => {
        this.spvisible = false;
        this.getList();
        console.log(res);
      });
    },
    getWorkerList() {
      this.$request.HttpGet("/worker/list").then((res) => {
        this.workerList = res.data.list;
      });
    },
  },
};
</script>

<style lang="scss">
.formline {
  margin-top: 20px;
}
.label-width {
  width: 202px;
}
.voucher-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}
.upload-imgx-box {
  position: relative;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: inline-block;
  .upload-img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    right: -10px;
    top: -10px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(110, 108, 108);
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
}
</style>
